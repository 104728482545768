import { GetAPI_deleteAPI_method } from "./Api";
import { APOData, templateJSON } from "./BuilderProps";
import { initdatepicker } from "./Calendar";
import { updateHandlebarsTemp } from "./CommonMethod";
import { getServiceNextStep } from "./Serivceconfig";
import { bookingForm_EventListeners } from "./SignupFrom";
import { showPaymentMode } from "./PaymentDetail";
import { APO__DataUpdate } from "./OnboardingData";
import { appointmentLayout } from "./ServiceFilterHtml";

window.filterAppointment = async (event) => {
  const value = event.target.value;
  // Set service type and APO type globally
  templateJSON.StyleObj["Service"].APOtype = value;
  APOData.APOtype = value;

  try {
    await appointmentLayout();
  } catch (error) {
    console.error("Error in filterAppointment:", error.message);
  }
};

export const get__ServiceEventClass = async () => {
  try {
    const { APOtype, onboardingData } = APOData;
    let data = [];

    // Fetch data if the type is not "Service" and the data is not already loaded
    switch (APOtype) {
      case "Service":
        data = onboardingData.serviceMenusList;
        break;
      default:
        const response = await GetAPI_deleteAPI_method(APOtype + (APOtype === "Class" ? "es" : "s"), "GET");
        data = response.data;
    }

    // Update and return the data for the given APOtype
    APOData[APOtype] = await APO__DataUpdate(data, APOtype);
    return APOData[APOtype];
  } catch (error) {
    console.error("Error fetching data:", error.message);
    return [];
  }
};

export const APO__chooseService = async () => {
  const { APOtype } = APOData;
  switch (APOtype) {
    case "Service":
    case "Appointment":
    case "Class":
      await Staff_Calendar(); // Proceed to select appointment step
      break;
    case "Event":
      APO__UserDetails();
      break;
    // default:
    //   APO__chooseDateTime();
  }
};

const Staff_Calendar = async () => {
  const { onboardingData, APO_selectedData, Bookingrules } = APOData;
  const { appointmentJSON, serviceQParams } = APO_selectedData;

  const checkParamsbyStep = serviceQParams.step === "stafflist" ? true : serviceQParams.slot;
  const { nextStep } = await getServiceNextStep(checkParamsbyStep);

  // Select the booking content element & Handlebars template
  const bookingContentElement = document.querySelector(".APO_booking_content");
  const template = document.getElementById(`template_${serviceQParams.step}`).innerHTML;

  // Filter the IsAssignAnyTeamMember & IsSkipTeamMembers rules
  const IsAssignAnyTeamMember = Bookingrules.bookingRulesLists.find((rule) => rule.keyName === "IsAssignAnyTeamMember");
  const IsSkipTeamMembers = Bookingrules.bookingRulesLists.find((rule) => rule.keyName === "IsSkipTeamMembers");

  const templateData = {
    ...APO_selectedData,
    serviceQParams,
    stafflist: onboardingData.staffMembersList,
    step: nextStep,
    isAssignAnyTeamMember: IsAssignAnyTeamMember.isSelected,
    SkipTeamMembers: IsSkipTeamMembers.isSelected,
  };

  // if IsAssignAnyTeamMember rule true
  if (IsAssignAnyTeamMember.isSelected && (serviceQParams.staffSelected === null || serviceQParams.staffSelected)) {
    // Fetch provider count data
    const ProviderBookingCount = await GetAPI_deleteAPI_method(
      "ProviderCount",
      "GET"
    );
    templateData.assignAnyStaff = ProviderBookingCount.data[0]
  }

  await updateHandlebarsTemp(bookingContentElement, template, templateData);

  if (["Datetime", "StafflistDatetime"].includes(serviceQParams.step)) {
    initdatepicker(); // Initialize calendar
  }
};

export const APO__UserDetails = async () => {
  // Select the booking content element
  const bookingContentElement = document.querySelector(".APO_booking_content");

  // Destructure necessary properties from StyleObj and APOData
  const { Signup, Service } = templateJSON.StyleObj;
  const { APO_selectedData, CountriesList, onboardingData } = APOData;

  // Check if CountriesList is empty and populate it if needed
  if (CountriesList.length === 0) {
    const placeholderTemplate = document.getElementById(
      `template_UserD-placeholderLoading`
    ).innerHTML; // Get Handlebars template
    await updateHandlebarsTemp(bookingContentElement, placeholderTemplate, "");

    const response = await GetAPI_deleteAPI_method("Countries", "GET");
    Object.assign(CountriesList, response.data);
  }

  // Check if AllcustomerID is not set and populate it if needed
  if (!APOData.AllcustomerID) {
    const customerListResponse = await GetAPI_deleteAPI_method("Lists", "GET");
    const readOnlyCustomer = customerListResponse.data.find(
      (item) => item.isReadOnly
    );

    if (readOnlyCustomer) {
      APOData.AllcustomerID = readOnlyCustomer.id;
    }
  }

  // Retrieve the last step template from the Service object
  const template = document.getElementById(`template_${Service.steps.slice(-1)[0].name}`).innerHTML; // Get Handlebars template

  // Prepare the order detail object
  const orderDetail = {
    ...APO_selectedData,
    CountriesList,
    form: Signup.form,
    UserCountries: onboardingData.userCountry,
  };

  // Update the booking content with the Handlebars template and order details
  await updateHandlebarsTemp(bookingContentElement, template, orderDetail);

  // Initialize custom select elements with search functionality
  $(".DropwithSearch").customSelect({ search: true });

  // Attach event listeners for the booking form
  bookingForm_EventListeners();
};

window.bookappointment = async (event, element) => {
  event.preventDefault(); // Prevent default form submission

  const formData = new FormData(event.target);

  APOData.userDetail = {
    name: formData.get("name"),
    emailAddress: formData.get("email"),
    phoneNumber: "+" + formData.get("country_code") + formData.get("number"),
    country: formData.get("country"),
    listId: APOData.AllcustomerID,
  };

  // Select the booking content element
  const bookingContentElement = document.querySelector(".APO_booking_content");
  const placeholderTemplate = document.getElementById(
    `template_UserD-placeholderLoading`
  ).innerHTML; // Get Handlebars template
  await updateHandlebarsTemp(bookingContentElement, placeholderTemplate, "");

  showPaymentMode();
};

window.APO__bookanother = (event, element) => {
  if (window.location.hash) {
    // Remove the hash
    history.replaceState(
      null,
      null,
      window.location.pathname + window.location.search
    );
    location.reload(); // Refresh the page
  }
};

window.serviceBackStep = (event) => {
  event.preventDefault();
  window.history.back();
};
