import { APOData } from "./BuilderProps";
import { GetAPI_deleteAPI_method } from "./Api";
import { updateHandlebarsTemp } from "./CommonMethod";

export const templateSetting = async () => {
  try {
    await updateOpeningHours(); // Update opening hours
    await updateContactData(); // Update contact details
    await defaultTemplateStyle(); // Apply default template style
  } catch (error) {
    console.error("Error in updating template settings:", error);
  }
};

export const themeTypeSetting = async () => {
  const { isTabs } = templateJSON;

  // Initialize jQuery UI tabs with custom active class handling
  if (isTabs) {
    $(".TabWidget .widge-TabContant").tabs({
      activate: function (event, ui) {
        ui.oldTab.removeClass("active");
        ui.newTab.addClass("active");
      },
    });
  }
};

const updateOpeningHours = async () => {
  try {
    const openingH = document.querySelector(".Bhours-list");
    const template = document.getElementById("template_Bhours").innerHTML;
    await updateHandlebarsTemp(openingH, template, APOData.onboardingData); // Compile Handlebars template method
  } catch (error) {
    console.error("Error updating opening hours:", error);
  }
};

const updateContactData = async () => {
  try {
    const addressSection = document.querySelector(".Baddress-detail");
    const template = document.getElementById("template_Baddress").innerHTML;
    await updateHandlebarsTemp(
      addressSection,
      template,
      APOData.onboardingData
    ); // Compile Handlebars template method
  } catch (error) {
    console.error("Error updating contact data:", error);
  }
};

export const fetchBookingRules = async () => {
  try {
    const response = await GetAPI_deleteAPI_method("BookingRules", "GET");
    APOData.Bookingrules = response.data;
    await applyBookingRulesToDomElements();
  } catch (error) {
    console.error("Error fetching booking rules:", error);
  }
};

export const filterBookingRule = async (allowedKeys) => {
  const { bookingRulesLists } = APOData.Bookingrules;

  if (allowedKeys.length > 1) {
    return bookingRulesLists.filter((rule) =>
      allowedKeys.includes(rule.keyName)
    );
  } else {
    return bookingRulesLists.find((rule) => rule.keyName === allowedKeys[0]);
  }
};

const applyBookingRulesToDomElements = async () => {
  try {
    // Rules related to removing DOM elements
    const removeDomElements = {
      removeIfTrue: {
        IsHideAppointUsBranding: ".widget.Footer",
      },
      removeIfFalse: {
        IsUserReviewEnabled: "[data-rule=IsUserReviewEnabled]",
      },
    };

    // Iterate over the keys of removeDomElements
    Object.entries(removeDomElements).forEach(async ([conditionType, elementsToRemove]) => {
        // Fetch and process the rules for each condition (removeIfTrue or removeIfFalse)
        for (const ruleName in elementsToRemove) {
          const selector = elementsToRemove[ruleName];

          // Fetch the rule status for each key (assuming filterBookingRule returns a single rule)
          const DomElementsRule = await filterBookingRule(ruleName);
          if (DomElementsRule && DomElementsRule.length > 0) {
            const rule = DomElementsRule[0];

            // Determine whether to remove the element based on the rule and condition type
            const shouldRemove =
              (conditionType === "removeIfTrue" && rule.isSelected) ||
              (conditionType === "removeIfFalse" && !rule.isSelected);

            if (shouldRemove && selector) {
              const elements = document.querySelectorAll(selector);
              elements.forEach((element) => element.remove());
            }
          }
        }
      });

  } catch (error) {
    console.error("Error applying Dom elements booking rules:", error);
  }
};

const defaultTemplateStyle = async () => {
  try {

    const { brandBannerUrl, brandLogoUrl } = APOData.onboardingData;
    
    const APOBanner = document.querySelector(".APO-banner");
    const APOLogo = document.querySelector(".APO-logo");
    if (brandBannerUrl && APOBanner) {
      APOBanner.style.backgroundImage = `url(${brandBannerUrl})`;
    }
    if (brandLogoUrl && APOLogo) {
      APOLogo.src = brandLogoUrl;
    }

  } catch (error) {
    console.error("Error fetching template style:", error);
  }
};
