export const urlParams = new URLSearchParams(window.location.search);
const {APIURL} = process.env;

export const getcookie = async (name) => {
  let nameEQ = name + "=";
  let ca = document.cookie.split(';');
  for(let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

export const PostAPI_PutAPI_method = async (url, method, post_data) => {
  try {
    const token = await getcookie('tk');
    const response = await fetch(`${APIURL}${url}`, {
      withCredentials: true,
      method: method,
      body: JSON.stringify(post_data),
      headers: {
        "content-type": "application/json",
        Authorization: token ? token : "",
      },
    });
    if (response.status === 200) {
      const data = await response.json();
      return { data, status: response.status };
    } else {
      return { data: null, status: response.status };
    }
  } catch (err) {
    console.warn(err);
  }
};
export const GetAPI_deleteAPI_method = async (url, method, target) => {
  try {
    const token = await getcookie('tk');
    const response = await fetch(`${APIURL}${url}`, {
      withCredentials: true,
      method: method,
      headers: {
        Authorization: token,
        target: target == undefined ? "" : target,
      },
    });
    if (response.status === 200) {
      const data = await response.json();
      return { data, status: response.status };
    } else {
      return { data: null, status: response.status };
    }
  } catch (err) {
    console.warn(err);
  }
};
