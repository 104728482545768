import { APOData } from "./BuilderProps";
import { PostAPI_PutAPI_method } from "./Api";
import {
  convertTimeToMinutes,
  convertUnixTimestamp,
  updateHandlebarsTemp,
} from "./CommonMethod";
import { filterBookingRule } from "./TemplateSettings";
import { setupStripe } from "./StripePayment";
import moment from "moment";
import { bookingConfirmation } from "./Routes";

window.handlePaymentMethodChange = (event) => {
  // Destructure necessary properties from  APOData
  const { stripePaymentElement } = APOData;

  const btnAPOcheckout = document.querySelector(".btn-APOcheckout");
  const bindpaymentElement = document.querySelector(".bindpaymentElement");
  const value = event.target.value;

  // appointment booking/pay with satus
  APOData.selectedPaymentMethod = value;

  if (value === "skip_payment") {
    btnAPOcheckout.textContent = "Confirm";
    if (stripePaymentElement) {
      stripePaymentElement.unmount();
      bindpaymentElement.querySelector(".thirdpartypaymentmethod").remove();
    }
  } else {
    btnAPOcheckout.textContent = "Pay & confirm";
    const html = `<div class="thirdpartypaymentmethod"><div id="payment-element"><span class="APO__loader APO__spin block mx-auto" style="width: 40px;"></span></div>`;
    bindpaymentElement.insertAdjacentHTML("beforeend", html);
    setupStripe();
  }
};

export const showPaymentMode = async () => {
  const { isPayPalConnected, isStripeConnected, isCashConnected } =
    APOData.paymentModeList;

  if (isPayPalConnected) {
    console.log("isCashConnected and isStripeConnected");
    // setupCashPayment(); // Call the function to handle cash payment setup if applicable
  } else if (isStripeConnected) {
    setupStripe(); // Call the function to handle PayPal setup if applicable
  } else {
    await APO__bookappointment(false);
  }
};

export const APO__bookappointment = async (paymentdata) => {
  try {
    const { userDetail, APO_selectedData, reschedule, BookingID, APOtype } = APOData;

    const appointmentButtonKeys = ["IsBookAppointmentButton"];
    const IsBookAppointmentButton = await filterBookingRule(
      appointmentButtonKeys
    );

    // Create customer record
    const customerResponse = await PostAPI_PutAPI_method(
      "Customer",
      "POST",
      userDetail
    );

    if (!customerResponse || customerResponse.status !== 200) {
      throw new Error("Failed to create customer.");
    }

    // Prepare appointment details
    const appointmentsObj = prepareAppointmentObject(customerResponse.data.id);

    // booking ID when Reschedule appointment
    reschedule ? (appointmentsObj.id = BookingID) : "";

    // Book the appointment
    const appointmentResponse = await PostAPI_PutAPI_method(
      "Appointments",
      reschedule ? "PUT" : "POST",
      appointmentsObj
    );

    if (appointmentResponse.status === 200) {
      // Update booking content with the checkout template
      const bookingContentElement = document.querySelector(
        ".APO_booking_content"
      );
      const template = document.getElementById(
        `template_confirmation`
      ).innerHTML; // get Handlebars template
      await updateHandlebarsTemp(bookingContentElement, template, {
        ...APO_selectedData,
        IsBookAppointmentButton,
        bookingStatus: appointmentResponse.data,
      });

      APO_selectedData.date = null;

      // if (appointmentResponse.data.success) {
      //   bookingConfirmation(appointmentResponse.data, APOtype);
      // }
    } else {
      throw new Error("Failed to book appointment.");
    }
  } catch (error) {
    const errorMessage = document.getElementById("error-message");
    console.error("Booking error:", error);
    errorMessage.innerText =
      error.message || "Something went wrong... Try again.";
  }
};

const prepareAppointmentObject = (customerId) => {
  const { userDetail, APO_selectedData, APOtype, AllcustomerID } = APOData;
  const { appointmentJSON, staffJSON, timeslot } = APO_selectedData;
  const {
    title,
    duration,
    startDate,
    endDate,
    image,
    location,
    price,
    currencySymbol,
  } = appointmentJSON;

  // Initialize appointment date strings
  let startDateStr = "";
  let endDateateStr = "";

  if (APOtype !== "Events") {
    startDateStr = convertUnixTimestamp(timeslot, "YYYY-MM-DDTHH:mm:ssZ");

    const totalDuration = convertTimeToMinutes(duration);
    let addDuration = moment.unix(timeslot).add(totalDuration, "minutes");
    endDateateStr = convertUnixTimestamp(
      addDuration.unix(),
      "YYYY-MM-DDTHH:mm:ssZ"
    ); // update endDateate
  }

  // Prepare appointment object
  return {
    title: "WebSite",
    serviceName: title,
    startDate: APOtype === "Events" ? startDate : startDateStr,
    endDate: APOtype === "Events" ? endDate : endDateateStr,
    customerList: [
      {
        id: customerId,
        name: userDetail.name,
        image: "",
      },
    ],
    listId: AllcustomerID,
    text: "Booking from website",
    color: appointmentJSON.color,
    eEventType:
      APOtype === "Service"
        ? "Appointment"
        : APOtype === "Events"
        ? "Event"
        : "Class",
    eNotificationType: "Email",
    ...(APOtype === "Service" && {
      serviceId: appointmentJSON.id,
      staffId: staffJSON.id,
      staffName: staffJSON.name,
    }),
    ...((APOtype === "Classes" || APOtype === "Service") && {
      price: price,
      currencySymbol: currencySymbol,
      duration: duration,
    }),
    ...(APOtype === "Classes" && { classId: appointmentJSON.id }),
    ...((APOtype === "Classes" || APOtype === "Events") && {
      image: image,
      location: location,
    }),
    ...(APOtype === "Events" && { eventId: appointmentJSON.id }),
  };
};
