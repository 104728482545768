import Handlebars from "handlebars";
import { templateJSON } from "./BuilderProps";
import { convertTimeToMinutes, convertUnixTimestamp, momentDateTimeFormat } from "./CommonMethod";

export const registerHelper = () => {
  const servicegridlist = document.getElementById(
    "commonHtmlof_servicegridlist"
  ).innerHTML;
  Handlebars.registerPartial("commonHtmlof_servicegridlist", servicegridlist);

  Handlebars.registerHelper("json", function (context) {
    return JSON.stringify(context, null, 2);
  });

  const SignupFormField = document.getElementById("template_SignupFormField").innerHTML; 
  Handlebars.registerPartial("SignupFormField", SignupFormField);

  const orderdetail = document.getElementById("template_orderdetail").innerHTML; 
  Handlebars.registerPartial("orderdetail", orderdetail);

  const PaymentPlaceholderLoading = document.getElementById("template_PaymentPlaceholderLoading").innerHTML;
  Handlebars.registerPartial("PaymentPlaceholderLoading", PaymentPlaceholderLoading);

  const template_servicegrid = document.getElementById("template_servicegrid").innerHTML;
  Handlebars.registerPartial("template_servicegrid", template_servicegrid);

  const filterApo = document.getElementById("template_filterApo");
  if (filterApo) {
    Handlebars.registerPartial("filterApo", filterApo.innerHTML);
  }

  const ApoBackStep = document.getElementById("template_ApoBackStep");
  if (filterApo) {
    Handlebars.registerPartial("ApoBackStep", ApoBackStep.innerHTML);
  }


  // Apply styles to the compiled template
  Handlebars.registerHelper("applyStyles", function (name, stylesKey, color) {
    var styles = templateJSON.StyleObj[name][stylesKey]; // Retrieve styles from StyleObj
    //if (!styles) return ''; // Check if styles is undefined or null
    return new Handlebars.SafeString(
      `style="${StylejsonFN(styles)}${color.data ? "" : ` color:${color}`}"`
    );
  });

  // apply class compiled template
  // Handlebars.registerHelper('checkWName', function(checkWName, option) {
  //   return `col-md-${(checkWName === 'Service') ? 4 : 12}`;
  // });

  // Apply event color to the compiled template
  Handlebars.registerHelper("eventColor", function (color, options) {
    return new Handlebars.SafeString(`style="color: ${color}"`);
  });

  // check string equal to
  Handlebars.registerHelper("if_eq", function (a, b, opts) {
    return a === b ? opts.fn(this) : opts.inverse(this);
  });

  // check string not equal to
  Handlebars.registerHelper("if_noteq", function (a, b, opts) {
    return a !== b ? opts.fn(this) : opts.inverse(this);
  });

  // format dates and times
  Handlebars.registerHelper("formatDateTime", function (dateString, format) {
    return momentDateTimeFormat(dateString, format);
  });
  
  // format Timestamp
  Handlebars.registerHelper("formatTimestamp", function (timestamp, format) {
    return (!!timestamp) ? convertUnixTimestamp(timestamp, format) : '';
  });

  // convert Time To Minutes
  Handlebars.registerHelper("convertTimeToMinutes", function (timeString) {
    const totalMinutes = convertTimeToMinutes(timeString);
    if (totalMinutes >= 60) {
      const remaininghours = Math.floor(totalMinutes / 60);
      const remainingMinutes = totalMinutes % 60;
      return `${remaininghours}h ${remainingMinutes}m`;
    } else {
      return `${totalMinutes}m`;
    }
  });

  // text lowerCase
  Handlebars.registerHelper('toLowerCase', function(str) {
    return str.toLowerCase();
  });

  // first&last letter of string
  Handlebars.registerHelper("returnOnlyZeroindex", function (name, optios) {
    return `${!name.split(' ')[1] ? `${name.substring(0, 1).toUpperCase()}${name.substring(name.length - 1, name.length).toUpperCase()}` : `${name.split(' ')[0][0].toUpperCase()}${name.split(' ')[1][0].toUpperCase()}`}`;
  });

  // check array Length
  Handlebars.registerHelper("ifArrayLength", function (array, opts) {
    return array && array.length > 0 ? opts.fn(this) : opts.inverse(this);
  });

  //print value with given value
  Handlebars.registerHelper("range", function (start, end) {
    let range = [];
    for (let i = start; i <= end; i++) {
      range.push(i);
    }
    return range;
  });
  
};

const StylejsonFN = (json) => {
  return Object.keys(json)
    .map(function (key) {
      return (
        key.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase() +
        ": " +
        json[key] +
        ";"
      );
    })
    .join("");
};
