
// global variable
export let APOData = {
  onboardingData: null,
  Bookingrules: null,
  Service: [],
  Events: [],
  Classes: [],
  APO_selectedData: {
    appointmentID: null,
    staffID: null,
    date: null,
    timeslot: null,
    appointmentJSON: null,
    staffJSON: null,
    serviceQParams: null,
    serviceActiveStep: "Servicelist",
  },			
  APOtype: "",
  CountriesList: [],
};
export const templateJSON = {};
globalThis.APOData = APOData;
globalThis.templateJSON = templateJSON;

