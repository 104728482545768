import {
  showValidationMessages,
  toggleSubmitButton,
  validateField,
} from "./FormValidation";

export const bookingForm_EventListeners = () => {
  const { CountriesList } = APOData;

  const checkoutContactForm = document.getElementById("checkoutContactForm");
  const APO__bookingBtn = checkoutContactForm.querySelector('button[type="submit"]');

  // Define validation schemas for each form
  const validationSchema = {
    name: [{ type: "isRequired" }],
    email: [{ type: "isRequired" }, { type: "isEmail" }],
    number: [{ type: "isRequired" }, { type: "isMobilePhone", locale: "any" }],
    country_code: [
      { type: "isRequired" },
      { type: "isIn", options: CountriesList.map((list) => list.phone_code) },
    ],
    country: [
      { type: "isRequired" },
      { type: "isIn", options: CountriesList.map((list) => list.name) },
    ],
  };

  // Common event handler function
  const handleFieldChange = (event) => {
    const { name, value } = event.target;
    const { isValid, errorMessage } = validateField(
      name,
      value,
      validationSchema
    );
    showValidationMessages(event.target, errorMessage);
    toggleSubmitButton(checkoutContactForm, validationSchema, APO__bookingBtn);
  };

    // Attach event listeners to form fields
    checkoutContactForm.addEventListener("keyup", handleFieldChange);
  $(".jqueryCustomSelect").on("change", handleFieldChange);
};
