import { APOData } from "./BuilderProps";
import { updateHandlebarsTemp, convertTimeToMinutes } from "./CommonMethod";
import moment from "moment";
import { getServiceNextStep, updateStaffJson } from "./Serivceconfig";
import { GetAPI_deleteAPI_method } from "./Api";

export const initdatepicker = () => {
  const { onboardingData, APO_selectedData } = APOData;
  const { businessHoursList } = onboardingData;

  let selectedDay = moment(new Date());
  if (!!APO_selectedData.date) {
    selectedDay = APO_selectedData.date;
  } else {
    APO_selectedData.date = selectedDay;
  }

  // Disable dates
  const disabledDays = businessHoursList
    .map((hours, index) => (!hours.isOpened ? (index + 1) % 7 : null))
    .filter((day) => day !== null)
    .join(",");

  $("#datepicker")
    .datepicker({
      // daysOfWeekDisabled: datesForDisable.replace(/,(\s+)?$/, ""),
      daysOfWeekDisabled: disabledDays,
      format: "mm/dd/yyyy",
      startDate: new Date(),
      weekStart: 1,
      todayHighlight: true,
      templates: {
        leftArrow: '<i class="fa fa-long-arrow-left"></i>',
        rightArrow: '<i class="fa fa-long-arrow-right"></i>',
      },
      beforeShowDay: function (date) {
        //return {
        //  content: `<span>${new Date(date).getDate()}</span>`
        //}
      },
    })
    .on("changeDate", function (e) {
      // const SDay = e.format("DD");
      const selectedDay = moment(e.date);
      APO_selectedData.date = selectedDay;
      document
        .querySelectorAll(".datepicker-days .day")
        .forEach((day) => day.classList.remove("today")); // Remove 'today' class on date change
      serviceDuration(
        selectedDay,
        APO_selectedData.appointmentJSON.duration,
        businessHoursList
      ); // check serive duration
    });

  $("#datepicker").datepicker("setDate", selectedDay.format("l"));
};

// Function to check service duration
const serviceDuration = (selectedDay, duration, hoursList) => {
  //.format("dddd")
  const dayName = selectedDay.format("dddd");
  const businessHours = hoursList.find((item) => item.day === dayName);
  if (!businessHours || !businessHours.isOpened) {
    console.error(
      `No business hours found for ${dayName} or the business is closed on ${dayName}.`
    );
    return;
  }
  const { from, to, breakHours } = businessHours;
  const startTime = moment(from).format("HH:mm");
  const endTime = moment(to).format("HH:mm");
  const totalMinutes = convertTimeToMinutes(duration); // convert time to minutes
  const timeData = { startTime, endTime, duration: totalMinutes, breakHours };
  generateServiceTimeSlots(timeData, selectedDay); // calculate service time slots
};

// Function to calculate service time slots
const generateServiceTimeSlots = async (timeData, selectedDay) => {
  // Destructure necessary data from APOData
  const { APO_selectedData, Bookingrules } = APOData;
  const { serviceQParams } = APO_selectedData;

  const { startTime, endTime, duration, breakHours } = timeData;

  const isToday = moment().format("DD") === selectedDay.format("DD");
  const isTodayDate = moment(new Date()).format("l") <= selectedDay.format("l");
  const currentTime = moment().format("HH:mm");
  // const isStartTimePassed = currentTime >= startTime;
  const availableServiceSlots = [];
  let timeSlot = moment(startTime, "HH:mm");

  // Filter the IsSkipTeamMembers rules
  const IsSkipTeamMembers = Bookingrules.bookingRulesLists.find((rule) => rule.keyName === "IsSkipTeamMembers");

  
  if (IsSkipTeamMembers.isSelected && !serviceQParams.staff) {
    // Fetch provider count data
    const ProviderBookingCount = await GetAPI_deleteAPI_method(
      "ProviderCount",
      "GET"
    );
    serviceQParams.staff = ProviderBookingCount.data[0].id;
    await updateStaffJson(serviceQParams.staff);
  }

  // Fetch booked slots for the selected provider on the selected day
  let bookedSlotAndBreakHours = [];
  const { staffJSON } = APOData.APO_selectedData;
  if (staffJSON) {
    const checkbookedslot = await checkSlotsforSelectedProvider(
      selectedDay,
      staffJSON,
      breakHours
    );

    // Combine booked slots with break hours
    bookedSlotAndBreakHours = [...checkbookedslot, ...breakHours];
  }

  while (timeSlot.isBefore(moment(endTime, "HH:mm"))) {
    const slotTime = timeSlot.format("HH:mm");

    if (!isToday || !isTodayDate || currentTime < slotTime) {
      const fullTimestamp = moment(selectedDay).set({
        hour: timeSlot.hour(),
        minute: timeSlot.minute(),
      });
      availableServiceSlots.push({
        slot: slotTime,
        timestamp: fullTimestamp.unix(),
        break: isWithinBreakTime(slotTime, bookedSlotAndBreakHours),
      });
    }
    // Increment the timeSlot by duration
    timeSlot = timeSlot.clone().add(duration, "minutes");
  }

  // Select the DOM element where the appointment slots will be rendered
  const SlotEle = document.querySelector(".Service .AppointmentSlot");

  // Get the Handlebars template from the script element
  const template = document.getElementById(`template_AvailableSlot`).innerHTML;

  const checkParamsbyStep =
    serviceQParams.step === "Datetime" ? true : staffJSON;
  const { nextStep } = await getServiceNextStep(checkParamsbyStep); // Determine the next step based on staff availability

  // Prepare data for the Handlebars template
  const templateData = {
    Bookingslots: availableServiceSlots, // Available service slots data
    serviceQParams,
    step: nextStep,
  };
  // Compile and update the Handlebars template with the prepared data
  await updateHandlebarsTemp(SlotEle, template, templateData);
};

// Function to check if the time is within break hours
const isWithinBreakTime = (time, breakHours) => {
  if (!breakHours || breakHours.length === 0) return false;
  return breakHours.some((breakTime) => {
    const breakStartTime = moment(breakTime.from).format("HH:mm");
    const breakEndTime = moment(breakTime.to).format("HH:mm");
    return time >= breakStartTime && time < breakEndTime;
  });
};

const checkSlotsforSelectedProvider = async (selectedDay, staffJSON) => {
  try {
    // Call the API to get events based on the staff ID
    const bookingList = await GetAPI_deleteAPI_method(
      `EventsByStaffId?StaffId=${staffJSON.id}`,
      "GET"
    );

    // Return an empty array if there are no bookings
    if (bookingList.data.length === 0) {
      return [];
    }

    // Filter bookings to include only those that match the selected day
    const filteredBookings = bookingList.data.filter((booking) =>
      moment(booking.startDate).isSame(selectedDay, "day")
    );

    // Map the filtered bookings to an array of objects with 'from' and 'to' times
    return filteredBookings.map((booking) => ({
      from: booking.startDate,
      to: booking.endDate,
    }));
  } catch (error) {
    console.error("Error fetching booking list:", error);
    throw error; // Re-throw the error to handle it in the calling function
  }
};
