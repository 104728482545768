import { templateJSON } from "./BuilderProps";
import { updateHandlebarsTemp } from "./CommonMethod";
import { APOData } from "./BuilderProps";
import { getServiceNextStep } from "./Serivceconfig";
import { get__ServiceEventsClasses } from "./Serivce";

export const appointmentLayout = async (result) => {
  const data = result ? result : await get__ServiceEventsClasses();
  if (data.length) {
    // Destructure necessary data from APOData and templateJSON
    const { APOtype, appointmentFilter, Bookingrules } = APOData;
    const { StyleObj } = templateJSON;
    const steps = StyleObj["Service"].steps;

    // Get the target element and template & Handlebars template
    const appendElement = document.querySelector(`.Service .APO_booking_content`);
    const template = document.getElementById(`template_service${StyleObj["Service"].layout}`).innerHTML;

    // Filter the IsSkipTeamMembers rules
    const IsSkipTeamMembers = Bookingrules.bookingRulesLists.find((rule) => rule.keyName === "IsSkipTeamMembers");

    // Determine the step to skip based on APOtype and other conditions
    let isSkipNextStep = "";
    const indexofDatetime = steps.findIndex((step) =>
      step.includes("Datetime")
    );

    switch (APOtype) {
      case "Events":
        isSkipNextStep = "UserDetails";
        break;
      case "Classes":
        isSkipNextStep = steps[indexofDatetime];
        break;
      default:
        const { nextStep } = await getServiceNextStep(true);
        isSkipNextStep = (IsSkipTeamMembers.isSelected) && nextStep === "stafflist" ? steps[indexofDatetime] : nextStep;
        break;
    }

    // Prepare the data for the template
    const templateData = {
      appointmentData: data,
      // stafflist: ProviderCount_res.data[0],
      step: isSkipNextStep,
      appointmentFilter,
    };

    // Compile and update the Handlebars template
    await updateHandlebarsTemp(appendElement, template, templateData);

    // Apply custom select functionality
    $(".S_customSelect").customSelect();
  }
};
