import Navigo from "navigo";
import { APO__chooseService, APO__UserDetails } from "./Serivce";
import { appointmentLayout } from "./ServiceFilterHtml";
import { APOData, templateJSON } from "./BuilderProps";
import { serviceQueryParams } from "./Serivceconfig";
import { findBookingFormListeners } from "./BookedAppointmentsList";
import { initializeReviews } from "./Review";
import { hideTeamSection } from "./Team";

export const router = new Navigo("/", { hash: true });

export const PageRouting = () => {
  router
    .on({
      "/": async () => {
        handleNavigation("Homepage");

        const {APOtype, APO_selectedData } = APOData;

        // if (!APO_selectedData.serviceActiveStep) {
        APO_selectedData.serviceActiveStep = "Servicelist";
        await appointmentLayout(APOData[APOtype]);
        // }
      },
      "/review": (parems) => {
        const { templatePageUnder } = templateJSON;
        templatePageUnder === "tabs"
          ? activateRouterTab(parems.route.name)
          : handleNavigation("Reviewpage");

        initializeReviews();
      },
      "/bookings/type/upcoming": async (parems) => {
        //clear booked appointment list
        const BookingWrapper = document.querySelector(".Booking .BK-wrapper");
        if(BookingWrapper) {
          BookingWrapper.innerHTML = "";
        }

        const { templatePageUnder } = templateJSON;
        templatePageUnder === "tabs"
          ? activateRouterTab(parems.route.name)
          : handleNavigation("Bookingpage");

          // find booking from event Listeners
          findBookingFormListeners()
        // await bookedAppointmentsList();
      },
      "/book": async (parems) => {
        const { templatePageUnder } = templateJSON;
        templatePageUnder === "tabs"
          ? activateRouterTab(parems.route.name)
          : handleNavigation("Homepage");

        scrollToServiceWidget();

        const { APOtype, APO_selectedData } = APOData;
        APO_selectedData.serviceActiveStep = "Servicelist";
        await appointmentLayout(APOData[APOtype]);
      },
      "/book/step=:step": async (parems) => {
        const { data } = parems;

        const { templatePageUnder } = templateJSON;
        templatePageUnder === "tabs"
          ? activateRouterTab("book")
          : handleNavigation("Homepage");

        const stepsData = await serviceQueryParams(`step=${data.step}`);
        handleStepNavigation(stepsData.step);
      },
    })
    .resolve();
};
router.updatePageLinks();

const handleNavigation = (page) => {
  // Navigate to the specified page
  navigatePage(page);

  // Function to update the active class on the menu items
  updateNavActiveClass();

  // Hide the team section if necessary (pass a parameter to control visibility)
  hideTeamSection(false); 
};

// Navigate to the specified page
const navigatePage = (pageClass) => {
  const allPages = document.querySelectorAll(".page-wrapper");

  allPages.forEach((page) => {
    const isCurrentPage = page.classList.contains(pageClass);
    page.classList.toggle("hidden", !isCurrentPage);

    if (page.classList.contains("Navbar") || page.classList.contains("Footer-section")) {
      page.classList.remove("hidden");
    }
  });
};

// Function to update the active class on the menu items
const updateNavActiveClass = () => {
  const navLinks = document.querySelectorAll(".nav-link");

  navLinks.forEach((link) => {
    const href =
      link.getAttribute("href") === "#" ? "" : link.getAttribute("href");
    link.classList.toggle("active", window.location.hash === href);
  });
};

const scrollToServiceWidget = () => {
  
  const section = document.querySelector(".Service[builder-element]");

  if (section) { 
    // Adjust the scroll position to add a 100px gap from the top
    setTimeout(() => {
      section.scrollIntoView({
        behavior: "smooth",
      });
    }, 300); // Set a delay to ensure the scroll happens after `scrollIntoView`
    
  }
  // Adjust the scroll position to add a 100px gap from the top
  // window.scrollBy(0, 100);
};

const handleStepNavigation = async (step) => {
  
  switch (step) {
    case "stafflist":
    case "Datetime":
    case "StafflistDatetime":
      await APO__chooseService();
      break;
    case "UserDetails":
      await APO__UserDetails();
      break;
    default:
      console.warn("Unknown step:", step);
  }
  
  // scrollToServiceWidget();
  // Hide the team section if necessary (pass a parameter to control visibility)
  hideTeamSection(true); 
};

export const activateRouterTab = (routeName) => {
  const routerTab = document.querySelector(`.TabWidget .widge-TabContant li[data-navigo="#/${routeName}"]`);

  // Hide the team section if necessary (pass a parameter to control visibility)
  hideTeamSection(false);

  // Get the parent <ul> element
  const ulElement = routerTab.parentElement;

  // Convert the children of the <ul> to an array and find the index
  const liElements = Array.from(ulElement.children);
  const index = liElements.indexOf(routerTab);

  if (!JSON.parse(routerTab.getAttribute("aria-selected"))) {
    $(ulElement.parentElement).tabs({ active: index });
  }
};

window.tabclickrouter = (event, element) => {
  const navigoLink = element.dataset.navigo;
  const path = navigoLink.split("#");
;
  if (path[1] === "/book") {
    const { APO_selectedData } = APOData;
    APO_selectedData.serviceActiveStep = "Servicelist";
  }
  router.navigate(path[1]);
};

export const bookingConfirmation = (bookingData, type) => {
  router.navigate(`/book?step=confirmation&type=${type}&confirmed=${bookingData.id}`);
}
