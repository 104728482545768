import { templateJSON } from "./BuilderProps";
import { getUrlParams, updateHandlebarsTemp } from "./CommonMethod";
import { APOData } from "./BuilderProps";
import { getServiceNextStep } from "./Serivceconfig";
import { get__ServiceEventClass } from "./Serivce";
import { displayTeamList } from "./Team";

const NextStep = async (steps, isSingleStaffResult) => {
  // Destructure necessary data from APOData and templateJSON
  const { APOtype, Bookingrules } = APOData;

  // Get index of the "Datetime" step and the skip rule for team members
  const indexOfDatetime = steps.findIndex((step) =>
    step.name.includes("Datetime")
  );
  const isSkipTeamMembers = Bookingrules.bookingRulesLists.find(
    (rule) => rule.keyName === "IsSkipTeamMembers"
  )?.isSelected;

  let nextStep = null;

  switch (APOtype) {
    case "Event":
      nextStep = "UserDetails";
      break;
    case "Class":
      nextStep = steps[indexOfDatetime].name;
      break;
    default:
      const { nextStep: serviceNextStep } = await getServiceNextStep(true);
      nextStep =
        (isSkipTeamMembers || isSingleStaffResult) &&
        serviceNextStep === "stafflist"
          ? steps[indexOfDatetime].name
          : serviceNextStep;
      break;
  }
  return nextStep;
};

export const appointmentLayout = async (result) => {
  const data = result ? result : await get__ServiceEventClass();
  if (data.length) {
    // Destructure necessary data from APOData and templateJSON
    const { appointmentFilter, onboardingData } = APOData;
    const { layout, steps } = templateJSON.StyleObj["Service"];

    // Get the target element and template & Handlebars template
    const appendElement = document.querySelector(`.Service .APO_booking_content`);
    const template = document.getElementById(`template_service${layout}`).innerHTML;

    const isSingleStaffResult = await getUrlParams("staff");

    // Prepare the data for the template
    const templateData = {
      appointmentData: data,
      step: await NextStep(steps, isSingleStaffResult),
      staffData: {
        singleStaff: isSingleStaffResult ? onboardingData.staffMembersList[0] : [],
        staffSelected: isSingleStaffResult ? false : true,
      },
      appointmentFilter,
    };

    // Compile and update the Handlebars template
    await updateHandlebarsTemp(appendElement, template, templateData);

    // Apply custom select functionality
    $(".S_customSelect").customSelect();
  }
  // display team list
  displayTeamList();
};
