import {
  GetAPI_deleteAPI_method,
  PostAPI_PutAPI_method,
} from "./Api";
import { APOData, templateJSON } from "./BuilderProps";
import { OnboardingData } from "./OnboardingData";
import { base64DecodeUnicode_HTML, getUrlParams, setSectionLoader } from "./CommonMethod";
import { fetchBookingRules, themeTypeSetting } from "./TemplateSettings";

const validateKey = async () => {
  const keyValidate = {
    token: await getUrlParams("Tid"),
    validity: "Long Live",
  };
  const keyResponse = await PostAPI_PutAPI_method(
    "ChatWindowToken",
    "POST",
    keyValidate
  );
  if (keyResponse.status === 400) {
    return false;
  } else {
    const {
      accesstoken,
      isCashConnected,
      isPayPalConnected,
      isStripeConnected,
      stripePublishableKey,
      stripeAccountId,
    } = keyResponse.data;

    // Set the token in the APIHandler instance and update the cookie
    const newToken = `Bearer ${accesstoken}`;
    document.cookie = "tk" + "=" + (newToken || "");

    // Update payment modes and Stripe data if connected
    APOData.paymentModeList = {
      isCashConnected,
      isPayPalConnected,
      isStripeConnected,
    }; // update accept payments perms

    if (isStripeConnected) {
      APOData.stripeData = {
        stripePublishableKey,
        stripeAccountId,
      };
    }

    return true;
  }
};

(async function () {
  //add loader
  setSectionLoader(true, "body")

  const isValidKey = await validateKey();
  if (!isValidKey) {
    console.log("Initialization aborted due to invalid key.");
    return;
  }

  // Call the function
  handleThemeLoading();
})();

async function handleThemeLoading() {
  try {
    const DomainId = await getUrlParams("did");
    const hostUrl = window.location.hostname;
    const apiUrl = `BusinessTemplateHTML?SubDomainName=${hostUrl}&DomainId=${DomainId}`;

    const response = await GetAPI_deleteAPI_method(apiUrl, "GET");
    const { data } = response;

    // Insert HTML and apply styles
    const insertData = document.getElementById("insertData");
    insertData.style.display = "none";
    insertData.innerHTML = base64DecodeUnicode_HTML(data.html);
    Object.assign(templateJSON, JSON.parse(data.metaJson));

    // Additional initialization methods
    await fetchBookingRules(); // Fetch and apply booking rules
    await themeTypeSetting();
    await OnboardingData(); // Load onboarding data
  } catch (error) {
    console.error("Error content:", error);
    // You may want to handle the error more gracefully here
  }
}
